import {Injectable} from "@angular/core";

@Injectable()
export class FilterOnlinePendingOrder {
  orderNumber: number;
  fromDate: string;
  toDate: string;
  paymentStatus: string;
  paymentType: string;
  delivaryStatus: string;
  deliveryCompany: number;
  customerName: string;
  custometMobile: string;
  billingAddress: string;
  shippingAddress: string;
  orderTotalPriceValue: string;
  orderLineItem: string;
  custometEmail: string;
  page: number = 1;
  size: number = 10;

  //m pos items filters
  barcode:string;
  details:string;
  printDescription:string;
  imageCode:string;
}
