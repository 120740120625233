import {Component, OnInit} from '@angular/core';
import {AppSettings} from '../../../settings/app-settings';
import {TOnlineOrderGrnService} from './service/t-online-order-grn.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {SnotifyService} from 'ng-snotify';
import {NgxSpinnerService} from 'ngx-spinner';
import {TOnlineOrder} from './model/t-online-order';
import {MTablePaginationData} from '../../common/other/m-table-pagination-data';
import {FilterOnlinePendingOrder} from './model/filter/filter-online-pending-order';
import {MReportService} from '../../report/service/m-report.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MReport} from '../../report/model/m-report';
import {MCurrentReport} from '../../report/model/m-current-report';
import {MCurrentReportParamiterValues} from '../../report/model/m-current-report-paramiter-values';
import {MDeliveryCompany} from './model/m-delivery-company';
import {TempShippingBillingAddress} from './model/temp-shipping-billing-address';
import {TempDeliveryRequest} from './model/temp-delivery-request';
import {MPosItem} from './model/m-pos-item';
import {Router} from '@angular/router';

@Component({
  selector: 'app-t-online-pending-order-print',
  templateUrl: './t-online-pending-order-print.component.html'
})
export class TOnlinePendingOrderPrintComponent implements OnInit {

  public selectOnlineOrders: any[] = [];
  public tOnlineOrder: TOnlineOrder;
  public tOnlineOrderAdvance: TOnlineOrder;
  public mTablePaginationData: MTablePaginationData;
  public filterOnlinePendingOrder: FilterOnlinePendingOrder;
  public selectAllOrders: boolean = false;
  public mDeliveryCompanys: MDeliveryCompany [] = [];
  public mDeleveryCompnySelect: MDeliveryCompany;
  public tempShippingBillingAddress: TempShippingBillingAddress;
  public mReport: MReport;
  public content: any;
  public mCurrentReport: MCurrentReport;
  public allOnlineOrders: boolean = true;
  public pendingOrdersOnly: boolean = false;
  public tempDeliveryRequestList: TempDeliveryRequest [] = [];
  public tempDeliveryRespondList: TempDeliveryRequest [] = [];
  public selectOrdersType: string;
  public mDeliveryRequestSuccess: number = 0;
  public mDeliveryRequestError: number = 0;
  public mPosItems: MPosItem [] = [];
  public hasError: boolean = false;

  constructor(private tOnlineOrderGrnService: TOnlineOrderGrnService,
              private mReportService: MReportService,
              public sanitizer: DomSanitizer,
              private ngxSmartModalService: NgxSmartModalService,
              private snotifyService: SnotifyService,
              private spinner: NgxSpinnerService,
              private router: Router) {
    this.filterOnlinePendingOrder = new FilterOnlinePendingOrder();
    this.tOnlineOrder = new TOnlineOrder();
    this.tOnlineOrderAdvance = new TOnlineOrder();
    this.mTablePaginationData = new MTablePaginationData();
    this.tempShippingBillingAddress = new TempShippingBillingAddress();
    this.mReport = new MReport();
    this.mCurrentReport = new MCurrentReport();
    this.mDeleveryCompnySelect = new MDeliveryCompany();
    this.clearIframe();
  }

  ngOnInit() {
    this.allMDeliveryCompany();
    this.selectOrdersType = 'ALL';
    this.onlineOrdersPagination('ALL');
    console.log(localStorage.getItem('currentUser'));
    if ('null' == localStorage.getItem('currentUser')) {
      this.router.navigate(['/login']);
    }
  }

  clearIframe() {
    this.tOnlineOrder.order_status_url = '../../../../assets/img/loading-iframe.gif';
    this.content = '../../../../assets/img/loading-iframe.gif';
  }

  updateBillingAndShippingAddress() {
    this.tempShippingBillingAddress = new TempShippingBillingAddress();
    //order number
    this.tempShippingBillingAddress.orderNumber = this.tOnlineOrderAdvance.name;
    //shipping address details
    this.tempShippingBillingAddress.shippingAddressName = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.name;
    this.tempShippingBillingAddress.shippingAddressCompany = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.company;
    this.tempShippingBillingAddress.shippingAddressAddress1 = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.address1;
    this.tempShippingBillingAddress.shippingAddressAddress2 = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.address2;
    this.tempShippingBillingAddress.shippingAddressCity = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.city;
    this.tempShippingBillingAddress.shippingAddressZip = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.zip;
    this.tempShippingBillingAddress.shippingAddressCountry = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.country;
    this.tempShippingBillingAddress.shippingAddressPhone = this.tOnlineOrderAdvance.shopifyJsonObject.shipping_address.phone;
    //billing address details
    this.tempShippingBillingAddress.billingAddressName = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.name;
    this.tempShippingBillingAddress.billingAddressCompany = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.company;
    this.tempShippingBillingAddress.billingAddressAddress1 = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.address1;
    this.tempShippingBillingAddress.billingAddressAddress2 = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.address2;
    this.tempShippingBillingAddress.billingAddressCity = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.city;
    this.tempShippingBillingAddress.billingAddressZip = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.zip;
    this.tempShippingBillingAddress.billingAddressCountry = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.country;
    this.tempShippingBillingAddress.billingAddressPhone = this.tOnlineOrderAdvance.shopifyJsonObject.billing_address.phone;
    //financial status
    this.tempShippingBillingAddress.financialStatus = this.tOnlineOrderAdvance.shopifyJsonObject.financial_status;
    this.tempShippingBillingAddress.gateway = this.tOnlineOrderAdvance.shopifyJsonObject.financial_status;

    this.tOnlineOrderGrnService.updateBillingAddressAndShippingAddress(this.tempShippingBillingAddress).subscribe((data) => {
      this.snotifyService.success('THIS ORDER BILLING ADDRESS AND SHIPPING ADDRESS UPDATE SUCCESSS' + data, AppSettings.MESSAGE_SUCCESS, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      this.tOnlineOrderAdvance = new TOnlineOrder();
      this.ngxSmartModalService.close('advanceOrderDetail');
    }, (e) => {
      this.snotifyService.error(e, AppSettings.MESSAGE_ERROR, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
    });
  }

  printPendingSelectOrdersBarcode(): void {
    this.clearIframe();
    this.mCurrentReport.parameterValues = new MCurrentReportParamiterValues();
    this.mReportService.individualReport(AppSettings.REPORT_GFLOCK_ONLINE_GRASSHOPPERS_BARCODE).subscribe((data) => {
      this.mCurrentReport.report = data;

      this.mReportService.listParameters(this.mCurrentReport.report).subscribe((paramiters) => {

        var selectOrders = '';
        for (var tOnlineSelectOrder of this.selectOnlineOrders) {
          selectOrders = selectOrders + ',' + '\'' + tOnlineSelectOrder.name + '\'';
        }
        while (selectOrders.charAt(0) === ',') {
          selectOrders = selectOrders.substr(1);
        }

        this.mCurrentReport.parameters = paramiters;
        this.mCurrentReport.parameterValues.order_name = selectOrders;

        this.mReportService.viewReport(this.mCurrentReport.report, this.mCurrentReport.parameters, this.mCurrentReport.parameterValues).subscribe((response) => {
          let file = new Blob([response], {type: 'application/pdf'});
          let fileURL = URL.createObjectURL(file);
          this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        }, (e) => {
          console.log(e);
          console.log('view report error');
        });

      }, (e) => {
        console.log('individual report error');
      });

    }, (e) => {
      console.log('individual report error');
    });

    this.ngxSmartModalService.open('popupOne');
  }

  printPendingSelectOrders(): void {
    this.clearIframe();
    this.mCurrentReport.parameterValues = new MCurrentReportParamiterValues();
    this.mReportService.individualReport(AppSettings.REPORT_GFLOCK_ONLINE_PENDING_ORDER_PRE_PRINT).subscribe((data) => {
      this.mCurrentReport.report = data;

      this.mReportService.listParameters(this.mCurrentReport.report).subscribe((paramiters) => {
        var printPendingPackingSlipCount=0;
        var selectOrders = '';
        for (var tOnlineSelectOrder of this.selectOnlineOrders) {
          selectOrders = selectOrders + ',' + '\'' + tOnlineSelectOrder.name + '\'';
          console.log(tOnlineSelectOrder.print_packing_slip_status);
          if (tOnlineSelectOrder.print_packing_slip_status=="PRINTED"){
            printPendingPackingSlipCount++;
          }
        }
        while (selectOrders.charAt(0) === ',') {
          selectOrders = selectOrders.substr(1);
        }
        if (printPendingPackingSlipCount>0){
          this.snotifyService.warning(printPendingPackingSlipCount+" Order(s) are Already Printed.", AppSettings.MESSAGE_WARNING, {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });
        }

        this.mCurrentReport.parameters = paramiters;
        this.mCurrentReport.parameterValues.order_name = selectOrders;

        this.mReportService.viewReport(this.mCurrentReport.report, this.mCurrentReport.parameters, this.mCurrentReport.parameterValues).subscribe((response) => {
          let file = new Blob([response], {type: 'application/pdf'});
          let fileURL = URL.createObjectURL(file);
          this.content = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        }, (e) => {
          this.snotifyService.error(e, AppSettings.MESSAGE_ERROR, {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true
          });
        });

      }, (e) => {
        console.log(e);
        this.snotifyService.error('REPOST PARAMITERS ERROR!', AppSettings.MESSAGE_ERROR, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
      });

    }, (e) => {
      console.log(e);
      this.snotifyService.error('REPORT VIEW ERROR!', AppSettings.MESSAGE_ERROR, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
    });

    this.ngxSmartModalService.open('popupOne');
  }

  onlineOrdersPagination(type: string): void {
    this.paginationChnage(1, type);
  }

  clearFilterOnlinePendingOrder(): void {
    this.filterOnlinePendingOrder = new FilterOnlinePendingOrder();
  }

  openOnlineOrderDetail(rowNo: number, tOnlineOrder: TOnlineOrder): void {
    // this.tOnlineOrder = new TOnlineOrder();
    // this.clearIframe();
    // this.tOnlineOrder = tOnlineOrder;
    // console.log("+++++++++++++++++++++");
    // console.log(JSON.parse(tOnlineOrder.shopifyJson));
    // console.log("+++++++++++++++++++++");
    // this.ngxSmartModalService.open('popupOne1');

    this.tOnlineOrderAdvance = new TOnlineOrder();
    this.tOnlineOrderAdvance = tOnlineOrder;
    this.ngxSmartModalService.open('advanceOrderDetail');
  }

  allMDeliveryCompany(): void {
    this.tOnlineOrderGrnService.allMDeliveryCompany().subscribe((data) => {
      this.mDeliveryCompanys = data;
    }, (e) => {
    });
  }

  updateShopifyOnlineOrderPrintPackingSlipStatusAndPrintBarcodeStatus(statusType: string): void {
    var selectOrders = '';
    console.log('AAA');
    for (var tOnlineSelectOrder of this.selectOnlineOrders) {
      this.tOnlineOrderGrnService.updateShopifyOnlineOrderPrintPackingSlipStatusAndPrintBarcodeStatus(statusType, tOnlineSelectOrder.name).subscribe((data) => {
        console.log('BBB');

      }, (e) => {
      });
    }
    console.log('CCC');
    this.selectOnlineOrders = [];
    for (const tOnlineOrder of this.mTablePaginationData.content) {
      tOnlineOrder.orderSelect = false;
    }
    console.log('DDD');
    this.snotifyService.success('PRINT PACKING SLIP UPDATE SUCCESS', AppSettings.MESSAGE_SUCCESS, {
      timeout: 2000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true
    });
  }

  syncShopifyOnlineOrderManual(): void {
    this.spinner.show();
    this.tOnlineOrderGrnService.syncShopifyOnlineOrderManual().subscribe((data) => {
      this.snotifyService.success('Shopify Online Orders Sync Success!' + data, AppSettings.MESSAGE_SUCCESS, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      if (this.selectOrdersType === 'PENDING') {
        this.onlineOrdersPagination('PENDING');
      } else {
        this.onlineOrdersPagination('ALL');
      }
      this.spinner.hide();
    }, (e) => {
      this.snotifyService.error('Shopify Online Orders Sync Fail', AppSettings.MESSAGE_ERROR, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      if (this.selectOrdersType === 'PENDING') {
        this.onlineOrdersPagination('PENDING');
      } else {
        this.onlineOrdersPagination('ALL');
      }
      this.spinner.hide();

    });
  }

  syncShopifyOnlineOrderManualSingle(): void {
    this.hasError = false;
    if (!this.filterOnlinePendingOrder.orderNumber) {
      this.snotifyService.error('Enter Order Number', AppSettings.MESSAGE_ERROR, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      this.hasError = true;
    }

    if (!this.hasError) {
      this.spinner.show();
      this.tOnlineOrderGrnService.syncShopifyOnlineOrderManualSingle(
        this.filterOnlinePendingOrder.orderNumber
      ).subscribe((data) => {
        this.snotifyService.success('Shopify Online Order CLB'+this.filterOnlinePendingOrder.orderNumber+' Sync Success!' + data, AppSettings.MESSAGE_SUCCESS, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        if (this.selectOrdersType === 'PENDING') {
          this.onlineOrdersPagination('PENDING');
        } else {
          this.onlineOrdersPagination('ALL');
        }
        this.spinner.hide();
      }, (e) => {
        this.snotifyService.error('Shopify Online Orders Sync Fail', AppSettings.MESSAGE_ERROR, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        if (this.selectOrdersType === 'PENDING') {
          this.onlineOrdersPagination('PENDING');
        } else {
          this.onlineOrdersPagination('ALL');
        }
        this.spinner.hide();

      });
    }
  }

  paginationChnage($event, type: string) {
    if ('PENDING' === type) {
      this.selectOrdersType = 'PENDING';
      this.spinner.show();
      this.tOnlineOrderGrnService.onlinePendingOrdersPagination(
        this.filterOnlinePendingOrder.orderNumber,
        this.filterOnlinePendingOrder.fromDate,
        this.filterOnlinePendingOrder.toDate,
        this.filterOnlinePendingOrder.paymentStatus,
        this.filterOnlinePendingOrder.paymentType,
        this.filterOnlinePendingOrder.delivaryStatus,
        $event,
        this.filterOnlinePendingOrder.size
      ).subscribe((data) => {
        this.mTablePaginationData = data;
        for (const tOnlineOrder of this.mTablePaginationData.content) {
          tOnlineOrder.shopifyJsonObject = JSON.parse(tOnlineOrder.shopifyJson);
          console.log(tOnlineOrder.shopifyJsonObject);
          tOnlineOrder.orderSelect = false;
          console.log(this.mTablePaginationData.content);
          this.spinner.hide();
        }
      }, (e) => {
        this.spinner.hide();
      });
      this.selectAllOrders = false;
    } else {
      this.selectOrdersType = 'ALL';
      this.spinner.show();
      this.tOnlineOrderGrnService.allOnlineOrders(
        this.filterOnlinePendingOrder.orderNumber,
        this.filterOnlinePendingOrder.fromDate,
        this.filterOnlinePendingOrder.toDate,
        this.filterOnlinePendingOrder.paymentStatus,
        this.filterOnlinePendingOrder.paymentType,
        this.filterOnlinePendingOrder.delivaryStatus,
        this.filterOnlinePendingOrder.deliveryCompany,
        $event,
        this.filterOnlinePendingOrder.size
      ).subscribe((data) => {
        this.mTablePaginationData = data;
        for (const tOnlineOrder of this.mTablePaginationData.content) {
          tOnlineOrder.shopifyJsonObject = JSON.parse(tOnlineOrder.shopifyJson);
          console.log(tOnlineOrder.shopifyJsonObject);
          tOnlineOrder.orderSelect = false;
          this.spinner.hide();
        }
      }, (e) => {
        this.spinner.hide();
      });
      this.selectAllOrders = false;
    }
    this.spinner.hide();
  }

  selectAllOrder(selectAllOrders: boolean): void {
    for (const tOnlineOrder of this.mTablePaginationData.content) {
      if (this.checkSelectOrderDuplicate(tOnlineOrder.name)) {
        tOnlineOrder.orderSelect = selectAllOrders;
        this.selectOnlineOrders.push(tOnlineOrder);
      } else {
        tOnlineOrder.orderSelect = selectAllOrders;
      }
    }

  }

  checkSelectOrderDuplicate(name: string): boolean {
    var status: boolean = true;
    for (const selectOnlineOrder of this.selectOnlineOrders) {
      if (name === selectOnlineOrder.name) {
        status = false;
        break;
      }
    }
    return status;
  }

  selectSingleOrder(tOnlineOrder: TOnlineOrder, rowNo: number): void {
    if (tOnlineOrder.orderSelect) {
      if (this.checkSelectOrderDuplicate(tOnlineOrder.name)) {
        this.selectOnlineOrders.push(tOnlineOrder);
      }
    } else {
      this.selectOnlineOrders.splice(rowNo, 1);
    }
  }

  removeSelectOnlineOrder(rowNo: number): void {
    this.selectOnlineOrders.splice(rowNo, 1);
  }

  clearRemoveSelectOnlineOrder(): void {
    this.selectAllOrders = false;
    this.selectOnlineOrders = [];
    if (this.selectOrdersType === 'PENDING') {
      this.onlineOrdersPagination('PENDING');
    } else {
      this.onlineOrdersPagination('ALL');
    }

    this.mDeliveryRequestSuccess = 0;
    this.mDeliveryRequestError = 0;
  }

  addNewMPosItemModal(): void {
    this.mPosItems = [];
    this.tOnlineOrderGrnService.findMPosItems(
      this.filterOnlinePendingOrder.barcode,
      this.filterOnlinePendingOrder.details,
      this.filterOnlinePendingOrder.printDescription,
      this.filterOnlinePendingOrder.imageCode)
      .subscribe((data) => {
        this.mPosItems = data;
      }, (e) => {
        console.log('findMPosItems error');
      });

    this.ngxSmartModalService.open('mPosItemsModal');
  }

  addNewMPosItem(mPosItem: MPosItem): void {
    this.tOnlineOrderGrnService.addNewMPosItem(this.tOnlineOrderAdvance.name, mPosItem.indexNo, mPosItem.qty, mPosItem.price).subscribe((data) => {
      this.tOnlineOrderAdvance.tOnlineOrderItemList.push(data);
      this.tOnlineOrderGrnService.updateTotalPricesAndShippingLineDetails(this.tOnlineOrderAdvance.order_number).subscribe((data) => {
        this.tOnlineOrderAdvance.shopifyJsonObject.total_line_items_price = data.totalLineItemsPrice;
        this.tOnlineOrderAdvance.shopifyJsonObject.shipping_lines[0].code = data.shippingLinesCode;
        this.tOnlineOrderAdvance.shopifyJsonObject.shipping_lines[0].price = data.shippingLinesPrice;
        this.tOnlineOrderAdvance.shopifyJsonObject.total_price = data.totalPrice;
        this.ngxSmartModalService.close('mPosItemsModal');
      }, (e) => {
        console.log('findMPosItems error');
      });
    }, (e) => {
      console.log('findMPosItems error');
    });
  }


  removeTOnlineOrderItem(tOnlineOrderItemIndexNo: number, status: string): void {
    this.snotifyService.confirm('Delete ?..', AppSettings.MESSAGE_WARNING, {
      timeout: 5000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      buttons: [
        {
          text: 'Yes', action: (toast) => {
            this.tOnlineOrderGrnService.removeTOnlineOrderItem(this.tOnlineOrderAdvance.order_number, tOnlineOrderItemIndexNo, status).subscribe((data) => {
              let rowId = this.tOnlineOrderAdvance.tOnlineOrderItemList.findIndex(x => x.indexNo === tOnlineOrderItemIndexNo);
              console.log(rowId);
              this.tOnlineOrderAdvance.tOnlineOrderItemList.splice(rowId, 1);
              this.tOnlineOrderAdvance.tOnlineOrderItemList.push(data);

              this.tOnlineOrderGrnService.updateTotalPricesAndShippingLineDetails(this.tOnlineOrderAdvance.order_number).subscribe((data) => {
                this.tOnlineOrderAdvance.shopifyJsonObject.total_line_items_price = data.totalLineItemsPrice;
                this.tOnlineOrderAdvance.shopifyJsonObject.shipping_lines[0].code = data.shippingLinesCode;
                this.tOnlineOrderAdvance.shopifyJsonObject.shipping_lines[0].price = data.shippingLinesPrice;
                this.tOnlineOrderAdvance.shopifyJsonObject.total_price = data.totalPrice;

                this.snotifyService.remove(toast.id);
              }, (e) => {
                console.log('findMPosItems error');
              });
            }, (e) => {
              console.log('findMPosItems error');
            });
          }
        },
        {
          text: 'No', action: (toast) => {
            this.snotifyService.remove(toast.id);
          }, bold: true
        },
      ]
    });
  }

  addDeliverRequest(): void {
    this.spinner.show();
    this.tempDeliveryRequestList = [];
    this.tempDeliveryRespondList = [];
    for (const tOnlineOrder of this.selectOnlineOrders) {
      if (tOnlineOrder.shopifyJsonObject.gateway === 'voided'){
        this.snotifyService.error('CANT DELIVERY VOID ORDERS', AppSettings.MESSAGE_ERROR, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        this.spinner.hide();
        return;
      }
      if (tOnlineOrder.delivery_status === 'PENDING') {
        var tempDeliveryRequest = new TempDeliveryRequest();
        tempDeliveryRequest.onlineOrder = tOnlineOrder.order_number;
        tempDeliveryRequest.deliverCompany = this.mDeleveryCompnySelect.indexNo;
        tempDeliveryRequest.status = false;
        this.tempDeliveryRequestList.push(tempDeliveryRequest);
      }
    }
    this.selectOnlineOrders = [];
    for (const tOnlineOrder of this.mTablePaginationData.content) {
      tOnlineOrder.orderSelect = false;
    }

    console.log(this.tempDeliveryRequestList);
    // delivery request status update
    if (this.tempDeliveryRequestList.length > 0) {
      this.tOnlineOrderGrnService.mDeliveryRequestSent(this.tempDeliveryRequestList).subscribe((data) => {

        this.tempDeliveryRespondList = data;
        for (const tempDeliveryRespond of this.tempDeliveryRespondList) {
          for (const tempDeliveryRequest of this.selectOnlineOrders) {
            if (tempDeliveryRequest.order_number === tempDeliveryRespond.onlineOrder) {
              if (tempDeliveryRespond.status === true) {
                tempDeliveryRequest.delivery_status = 'SENT';
                tempDeliveryRequest.deliveryCompany = this.mDeleveryCompnySelect;
              } else {
                tempDeliveryRequest.delivery_status = 'ERROR';
              }
            }
          }
        }

        //delivery request remove all request ui
        // for (const tempDeliveryRespond of this.tempDeliveryRespondList) {
        //   for (const tempDeliveryRequest of this.mTablePaginationData.content) {
        //     if (tempDeliveryRequest.order_number === tempDeliveryRespond.onlineOrder) {
        //       let server = this.mTablePaginationData.content.findIndex(x => x.order_number === tempDeliveryRespond.onlineOrder);
        //       this.mTablePaginationData.content.splice(server, 1);
        //     }
        //   }
        // }

        //refresh
        this.mDeliveryRequestSuccess = 0;
        this.mDeliveryRequestError = 0;
        for (const tempDeliveryRespond of this.tempDeliveryRespondList) {
          console.log(tempDeliveryRespond);
          if (tempDeliveryRespond.status === true) {
            this.mDeliveryRequestSuccess += 1;
          } else {
            this.mDeliveryRequestError += 1;
          }
        }

        if (this.selectOrdersType === 'PENDING') {
          this.onlineOrdersPagination('PENDING');
        } else {
          this.onlineOrdersPagination('ALL');
        }

        this.snotifyService.success('DILEVERY REQUEST SENT SUCCESSS', AppSettings.MESSAGE_SUCCESS, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });

        this.spinner.hide();

      }, (e) => {
        this.snotifyService.error(e, AppSettings.MESSAGE_ERROR, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        if (this.selectOrdersType === 'PENDING') {
          this.onlineOrdersPagination('PENDING');
        } else {
          this.onlineOrdersPagination('ALL');
        }
        this.spinner.hide();
      });
    } else {
      this.snotifyService.error('SELECT PENDING ORDERS TO DELIVER', AppSettings.MESSAGE_SUCCESS, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      this.spinner.hide();
    }
  }

  mDeliveryRequestReturn(): void {
    this.spinner.show();
    this.tempDeliveryRequestList = [];
    this.tempDeliveryRespondList = [];

    for (const tOnlineOrder of this.selectOnlineOrders) {
      var tempDeliveryRequest = new TempDeliveryRequest();
      tempDeliveryRequest.onlineOrder = tOnlineOrder.order_number;
      tempDeliveryRequest.deliverCompany = 0;
      tempDeliveryRequest.status = false;
      this.tempDeliveryRequestList.push(tempDeliveryRequest);
    }

    console.log(this.tempDeliveryRequestList);
    //delivery request status update
    this.tOnlineOrderGrnService.mDeliveryRequestReturn(this.tempDeliveryRequestList).subscribe((data) => {

      this.tempDeliveryRespondList = data;
      for (const tempDeliveryRespond of this.tempDeliveryRespondList) {
        for (const tempDeliveryRequest of this.selectOnlineOrders) {
          if (tempDeliveryRequest.order_number === tempDeliveryRespond.onlineOrder) {
            if (tempDeliveryRespond.status === true) {
              tempDeliveryRequest.delivery_status = 'RETURN';
              tempDeliveryRequest.deliveryCompany = null;
            } else {
              tempDeliveryRequest.delivery_status = 'ERROR';
            }
          }
        }
      }

      //refresh
      this.mDeliveryRequestSuccess = 0;
      this.mDeliveryRequestError = 0;
      for (const tempDeliveryRespond of this.tempDeliveryRespondList) {
        console.log(tempDeliveryRespond);
        if (tempDeliveryRespond.status === true) {
          this.mDeliveryRequestSuccess += 1;
        } else {
          this.mDeliveryRequestError += 1;
        }
      }

      if (this.selectOrdersType === 'PENDING') {
        this.onlineOrdersPagination('PENDING');
      } else {
        this.onlineOrdersPagination('ALL');
      }

      this.snotifyService.success('DILEVERY REQUEST RETURN SUCCESSS', AppSettings.MESSAGE_SUCCESS, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });

      this.spinner.hide();

    }, (e) => {
      this.snotifyService.error(e, AppSettings.MESSAGE_ERROR, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true
      });
      if (this.selectOrdersType === 'PENDING') {
        this.onlineOrdersPagination('PENDING');
      } else {
        this.onlineOrdersPagination('ALL');
      }
      this.spinner.hide();
    });
  }
}
